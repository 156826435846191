import { TranslationsManager } from '@resources/translation'

export const registerPtBrTranslations = (
  translationsManager: typeof TranslationsManager,
): void => {
  translationsManager.add('ptBr', 'performanceReport', {
    title: 'RELATÓRIO DE PERFOMANCE',
    buttonExport: 'Exportar',
    total: 'Total BUY',
    foreigner: 'Moeda Rival',
    averageRate: 'TX Média',
    valueInReais: 'Moeda Local',
    desiredPerformance: 'PERFORM',
    target: 'ALVO',
    spot: 'SPOT',
    marketAnalysis: 'Mercado', //'Analise Mercado',
    suggestedAction: 'Tesouraria', //'Ação Sugerida'
    suggestedMarket: 'Mercado', //'Ação Sugerida'
    performanceFee: 'Execução Sugerida',
    performancePercentage: 'PIPS',
    revenue: 'VALOR',
  })
}
