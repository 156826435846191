import { useState, memo } from 'react'

// import Typography, { FontType } from '@components/UI/Typography'
import Icon from '@components/UI/Icon'
import Field from '@components/UI/Field'
// import { theme } from '@resources/theme'

import * as SC from '../styles'

const TxPerfomance = ({
  dealtCcy,
  disabled,
  isLoadingMutate,
  updateReporteFee,
  defaultValue,
}: {
  dealtCcy: string
  disabled: boolean
  isLoadingMutate: boolean
  updateReporteFee: (coin: string, value: string) => void
  defaultValue: number
}) => {
  const [value, setValue] = useState(String(defaultValue).padEnd(6, '0'))

  return (
    <>
      <Field
        id={`txPerfor-${dealtCcy}`}
        name={dealtCcy}
        value={value}
        inputWidth={80}
        inputHeight={30}
        onChange={(event) => {
          const value = event.target.value.replace('$', '')

          setValue(value)
        }}
        // {...formik.getFieldProps(item.dealtCcy)}
        mask={'9.9999'}
        disabled={disabled}
      />

      <SC.WrapperIcon>
        <Icon
          name="RxUpdate"
          onCallback={() =>
            isLoadingMutate ? null : updateReporteFee(dealtCcy, value)
          }
          alt="Atualizar Spread"
          title="Atualizar Spread"
        />
      </SC.WrapperIcon>
    </>
  )
}

export default memo(TxPerfomance)
