import { useQuery } from 'react-query'

import { QuotesService } from '@services/quotes'
import {
  IQuotesByPairs,
  IMarketStatusResponse,
} from '@resources/types/services/quotes'

export const useQuotesByPairs = (pairs: string, interval = 1000) => {
  return useQuery<IQuotesByPairs[], Error>(
    ['quotesByPairs', pairs],
    () => {
      return QuotesService.getQuotesByPairs(pairs)
    },
    {
      refetchInterval: interval,
    },
  )
}

export const useMarketStatus = () => {
  return useQuery<IMarketStatusResponse, Error>(['marketStatus'], () => {
    return QuotesService.getMarketStatus()
  })
}
