import { Fragment, memo } from 'react'
import dayjs from 'dayjs'

import { t } from '@resources/translation'
import PageTitle from '@components/UI/PageTitle'
import Separator from '@components/UI/Separator'
import Typography, { FontType } from '@components/UI/Typography'
import { theme } from '@resources/theme'
import { generateKey } from '@resources/utils/generateKey'
import Icon from '@components/UI/Icon'
import Loading from '@components/Loading'
import FieldSelect from '@components/UI/FieldSelect'
import PivotPoint from '@components/PivotPoint'
import Button from '@components/UI/Button'

import ModalOperationMatching from './ModalOperationMatching'
import TxPerfomance from './TxPerfomance'
import * as SC from './styles'
import { usePerformanceReport } from './usePerformanceReport'

const PerformanceReport = () => {
  const {
    isLoading,
    data,
    pivotPointData,
    formatDolar,
    formatReal,
    updateReporteFee,
    isLoadingMutate,
    filteredDataPerfomance,
    suggestedActionFilter,
    setSuggestedActionFilter,
    handleCheckedOperations,
    checkedOperations,
    handleSubmitOperationsMatching,
    submitOperationsMatchingIsValid,
    showModalOperationMatching,
    handleCloseModal,
    dataOperationMatching,
  } = usePerformanceReport()

  const getColorBg = (value: number) => {
    return value % 2 == 1 ? `${theme.palette.neutral.dark}50` : 'transparent'
  }

  const getColor = (type: string) => {
    const setup = {
      Erro: theme.palette.error.dark,
      Zerar: theme.palette.error.dark,
      Aguardar: theme.palette.warning.regular,
      Executar: theme.palette.success.dark,
    }

    return setup[type] ?? theme.palette.neutral.regular
  }

  const getTableHead = (pair: string) => {
    return (
      <thead>
        <tr style={{ backgroundColor: 'transparent' }}>
          <td colSpan={6} style={{ textAlign: 'center' }}>
            <Separator verticalSize={30} />
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.lightest}
              size={25}
            >
              Posição {pair}
            </Typography>
            <Separator verticalSize={15} />
          </td>
        </tr>
        <tr style={{ backgroundColor: theme.palette.neutral.dark }}>
          <td style={{ textAlign: 'center', width: 50 }}></td>
          <td style={{ textAlign: 'center', height: 40 }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.light}
              size={14}
            >
              ID
            </Typography>
          </td>
          <td style={{ textAlign: 'center', height: 40 }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.light}
              size={14}
            >
              TX Média
            </Typography>
          </td>
          <td style={{ textAlign: 'center' }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.light}
              size={14}
            >
              VLR Estrang
            </Typography>
          </td>
          <td style={{ textAlign: 'center' }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.light}
              size={14}
            >
              VLR Reais
            </Typography>
          </td>
          <td style={{ textAlign: 'center' }}>
            <Typography
              fontType={FontType.bold}
              color={theme.palette.primary.light}
              size={14}
            >
              Data de Registro
            </Typography>
          </td>
        </tr>
      </thead>
    )
  }

  if (!data?.data.length) {
    return (
      <SC.Container>
        <SC.WrapperHeader>
          <PageTitle>{t('performanceReport.title')}</PageTitle>
        </SC.WrapperHeader>

        <Separator verticalSize={200} />

        <Typography
          fontType={FontType.bold}
          color={theme.palette.neutral.regular}
          size={16}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            Sem registro de negociação
          </div>
        </Typography>
        <Separator verticalSize={300} />
      </SC.Container>
    )
  }

  if (isLoading || isLoadingMutate) {
    return (
      <SC.Container>
        <SC.WrapperHeader>
          <PageTitle>{t('performanceReport.title')}</PageTitle>
        </SC.WrapperHeader>

        <Separator verticalSize={200} />

        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>

        <Separator verticalSize={300} />
      </SC.Container>
    )
  }

  return (
    <SC.Container>
      <SC.WrapperHeader>
        <PageTitle>{t('performanceReport.title')}</PageTitle>

        {/* <Button onClick={() => ''}>
          {t('operationalManagementReport.buttonExport')}
        </Button> */}
        {/* <SC.ButtonExport {...csvReport}>
          {t('performanceReport.buttonExport')}
        </SC.ButtonExport> */}
      </SC.WrapperHeader>

      <table
        border={0}
        style={{
          width: '100%',
          height: '100%',
          textAlign: 'right',
          padding: 10,
        }}
      >
        {data?.data.map((item) => (
          <Fragment key={generateKey()}>
            {getTableHead(item.dealt)}

            <tbody>
              <tr>
                {/* <td style={{ textAlign: 'center', width: 50 }}></td> */}
                <td
                  colSpan={6}
                  style={{
                    textAlign: 'left',
                    height: 30,
                    background: `${theme.palette.success.darkest}`,
                  }}
                >
                  <Typography
                    fontType={FontType.bold}
                    color={theme.palette.primary.light}
                    size={14}
                  >
                    &nbsp;Compra
                  </Typography>
                </td>
              </tr>

              {item?.buy.map((buy, indexBuy) => (
                <tr
                  key={buy.id}
                  style={{
                    height: 30,
                  }}
                >
                  <td style={{ textAlign: 'center' }}>
                    <input
                      type="checkbox"
                      name="text"
                      checked={
                        !!checkedOperations.find((item) => item.id === buy.id)
                      }
                      onChange={() =>
                        handleCheckedOperations(buy.id, buy.dealtCcy)
                      }
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexBuy),
                    }}
                  >
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      &nbsp;{buy.id}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexBuy),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{buy.allInRate}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      background: getColorBg(indexBuy),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{formatDolar(buy.dealtAmount)}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      background: getColorBg(indexBuy),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{formatReal(buy.contraAmount)}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexBuy),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;
                      {dayjs(buy.createdAt).format('DD/MM/YYYY - HH[h]ss')}
                    </Typography>
                  </td>
                </tr>
              ))}

              {!item?.buy.length && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.regular}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      &nbsp;Sem dados
                    </Typography>

                    <Separator verticalSize={10} />
                  </td>
                </tr>
              )}

              <tr>
                {/* <td style={{ textAlign: 'center', width: 50 }}></td> */}
                <td
                  colSpan={6}
                  style={{
                    textAlign: 'left',
                    height: 30,
                    background: `${theme.palette.error.light}`,
                  }}
                >
                  <Typography
                    fontType={FontType.bold}
                    color={theme.palette.primary.light}
                    size={14}
                  >
                    &nbsp;Venda
                  </Typography>
                </td>
              </tr>

              {item?.sell.map((sell, indexSell) => (
                <tr
                  key={sell.id}
                  style={{
                    height: 30,
                  }}
                >
                  <td
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      name="text"
                      checked={
                        !!checkedOperations.find((item) => item.id === sell.id)
                      }
                      onChange={() =>
                        handleCheckedOperations(sell.id, sell.dealtCcy)
                      }
                    />
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexSell),
                    }}
                  >
                    <Typography
                      fontType={FontType.bold}
                      color={theme.palette.primary.light}
                      size={14}
                    >
                      &nbsp;{sell.id}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexSell),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{sell.allInRate}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      background: getColorBg(indexSell),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{formatDolar(sell.dealtAmount)}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'right',
                      background: getColorBg(indexSell),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      &nbsp;{formatReal(sell.contraAmount)}
                    </Typography>
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      background: getColorBg(indexSell),
                    }}
                  >
                    <Typography
                      fontType={FontType.medium}
                      color={theme.palette.primary.light}
                      size={12}
                    >
                      {dayjs(sell.createdAt).format('DD/MM/YYYY - HH[h]ss')}
                    </Typography>
                  </td>
                </tr>
              ))}

              {!item?.sell.length && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <Typography
                      fontType={FontType.regular}
                      color={theme.palette.neutral.regular}
                      size={14}
                    >
                      &nbsp;Sem negociação
                    </Typography>

                    <Separator verticalSize={10} />
                  </td>
                </tr>
              )}
            </tbody>
          </Fragment>
        ))}
      </table>

      <Separator verticalSize={20} />

      <SC.WrapperPositionFilter>
        <Button
          width={300}
          height={40}
          primary
          onClick={handleSubmitOperationsMatching}
          disabled={!submitOperationsMatchingIsValid}
        >
          <Typography
            fontType={FontType.bold}
            color={
              !submitOperationsMatchingIsValid
                ? theme.palette.secondary.light
                : theme.palette.primary.regular
            }
            size={16}
          >
            Casar de Operações
          </Typography>
        </Button>
      </SC.WrapperPositionFilter>

      <Separator verticalSize={40} />

      <SC.WrapperPositionFilter>
        <Icon name="FaFilter" />
        <Typography
          fontType={FontType.bold}
          color={theme.palette.neutral.regular}
          size={25}
        >
          Filtro de Posição:
        </Typography>

        <SC.WrapperFieldFilter>
          <FieldSelect
            label=""
            name="suggestedActionFilter"
            options={[
              {
                label: 'Todos',
                value: '',
              },
              {
                label: 'Vendido',
                value: 'buy',
              },
              {
                label: 'Comprado',
                value: 'sell',
              },
            ]}
            selected={suggestedActionFilter}
            handleChange={(value) => {
              setSuggestedActionFilter(value)
            }}
          />
        </SC.WrapperFieldFilter>
      </SC.WrapperPositionFilter>

      {!isLoading && (
        <SC.WrapperConsolidated>
          <SC.WrapperTotals>
            <table
              border={0}
              style={{
                width: '100%',
                textAlign: 'right',
                padding: 10,
              }}
            >
              {filteredDataPerfomance?.map((item) => {
                const pivotPointWithTime = pivotPointData?.find(
                  (point) => point.symbol === `${item.dealtCcy}/BRL`,
                )

                return (
                  <Fragment key={generateKey()}>
                    <thead>
                      <tr
                        style={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <td
                          colSpan={10}
                          style={{
                            textAlign: 'center',
                            padding: '40px 0',
                          }}
                        >
                          <SC.WrapperPerformance>
                            <Typography
                              fontType={FontType.bold}
                              color={theme.palette.primary.lightest}
                              size={25}
                            >
                              Performance {item.dealtCcy}
                            </Typography>
                          </SC.WrapperPerformance>
                          <div
                            style={{
                              position: 'relative',
                              zIndex: 0,
                            }}
                          >
                            <SC.WrapperPivotPoint>
                              {pivotPointWithTime?.pivotPoits?.map((pivot) => (
                                <SC.WrapperTimePivotPoin key={generateKey()}>
                                  <SC.TimePivotPoint>
                                    <Typography
                                      fontType={FontType.semiBold}
                                      color={theme.palette.primary.darkest}
                                      size={14}
                                    >
                                      {pivot.timeFrame} MIN
                                    </Typography>
                                  </SC.TimePivotPoint>

                                  <SC.TimePivotPointData>
                                    <PivotPoint singularData={pivot} />
                                  </SC.TimePivotPointData>
                                </SC.WrapperTimePivotPoin>
                              ))}
                            </SC.WrapperPivotPoint>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            textAlign: 'center',
                            padding: '10px 0',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                          }}
                        >
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            Saldo
                          </Typography>
                        </td>
                        <td colSpan={2}></td>
                        <td
                          colSpan={2}
                          style={{
                            textAlign: 'center',
                            padding: '10px 0',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                          }}
                        >
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            ANALISE DE MERCADO
                          </Typography>
                        </td>
                        <td
                          colSpan={2}
                          style={{
                            textAlign: 'center',
                            padding: '10px 0',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                          }}
                        >
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            Ação Sugerida
                          </Typography>
                        </td>
                        <td
                          colSpan={2}
                          style={{
                            textAlign: 'center',
                            padding: '10px 0',
                            borderTop: '1px solid black',
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                          }}
                        >
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            Receita
                          </Typography>
                        </td>
                      </tr>
                      <tr
                        style={{ backgroundColor: theme.palette.neutral.dark }}
                      >
                        {/* Moeda */}
                        <td
                          style={{
                            textAlign: 'center',
                            height: 40,
                            padding: '0px 5px',
                          }}
                        >
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.foreigner')}
                          </Typography>
                        </td>

                        {/* Moeda Local */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.valueInReais')}
                          </Typography>
                        </td>

                        {/* TX MÉDIA */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.averageRate')}
                          </Typography>
                        </td>

                        {/* SPOT */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.spot')}
                          </Typography>
                        </td>

                        {/* PERFOM */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.desiredPerformance')}
                          </Typography>
                        </td>

                        {/* ALVO */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.target')}
                          </Typography>
                        </td>

                        {/* Tesouraria */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.suggestedAction')}
                          </Typography>
                        </td>

                        {/* Mercado */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.suggestedMarket')}
                          </Typography>
                        </td>

                        {/* PIPS */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.performancePercentage')}
                          </Typography>
                        </td>

                        {/* VALOR */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.bold}
                            color={theme.palette.primary.light}
                            size={14}
                          >
                            {t('performanceReport.revenue')}
                          </Typography>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{
                          height: '30px',
                          background: `${theme.palette.neutral.dark}50`,
                        }}
                      >
                        {/* Moeda Rival */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            {formatDolar(item?.vlrEstrang)}
                            &nbsp;
                          </Typography>
                        </td>

                        {/* Moeda Local */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            {formatReal(item?.vrlReais)}
                            &nbsp;
                          </Typography>
                        </td>

                        {/* TX Média */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            &nbsp;
                            {Number(item?.txMedia).toFixed(4)}
                            &nbsp;
                          </Typography>
                        </td>

                        {/* SPOT */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            {Number(item?.spot).toFixed(4)}
                          </Typography>
                        </td>

                        {/* PERFORM */}
                        <td>
                          <SC.WrapperAlignField>
                            <TxPerfomance
                              dealtCcy={item.dealtCcy}
                              defaultValue={item.txPerfor}
                              updateReporteFee={updateReporteFee}
                              disabled={isLoadingMutate}
                              isLoadingMutate={isLoadingMutate}
                            />
                          </SC.WrapperAlignField>
                        </td>

                        {/* ALVO */}
                        <td>
                          <td>
                            <Typography
                              fontType={FontType.medium}
                              color={theme.palette.primary.light}
                              size={12}
                            >
                              &nbsp;
                              {Number(item?.target).toFixed(4)}
                              &nbsp;
                            </Typography>
                          </td>
                        </td>

                        {/* TESOURARIA */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          {/* <SC.WrapperAlignField> */}
                          <Typography
                            fontType={FontType.semiBold}
                            color={getColor(item?.treasurer)}
                            size={15}
                          >
                            {item?.treasurer}
                            &nbsp;
                          </Typography>
                          {/* {item?.vlrEstrang > 0 ? (
                              <Icon name="BiTrendingDown" />
                            ) : (
                              <Icon name="BiTrendingUp" />
                            )} */}
                          {/* </SC.WrapperAlignField> */}
                        </td>

                        {/* Mercado */}
                        <td style={{ textAlign: 'center', padding: '0px 5px' }}>
                          <Typography
                            fontType={FontType.semiBold}
                            color={getColor(item?.market)}
                            size={15}
                          >
                            {item?.market}
                            &nbsp;
                          </Typography>
                        </td>

                        {/* PIPS */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            {Number(item?.percentPerfor).toFixed(4)}
                            &nbsp;
                          </Typography>
                        </td>

                        {/* VALOR */}
                        <td>
                          <Typography
                            fontType={FontType.medium}
                            color={theme.palette.primary.light}
                            size={12}
                          >
                            {formatReal(item?.revenue)}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </Fragment>
                )
              })}
            </table>
          </SC.WrapperTotals>
        </SC.WrapperConsolidated>
      )}

      <ModalOperationMatching
        display={showModalOperationMatching}
        closeModal={handleCloseModal}
        data={dataOperationMatching}
      />
    </SC.Container>
  )
}

export default memo(PerformanceReport)
