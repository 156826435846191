import styled from '@emotion/styled'

import { DefaultProps } from '@resources/types'

export const SectionMain = styled.div<DefaultProps>`
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SectionValues = styled.div<DefaultProps>`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`

export const WrapperTable = styled.div<DefaultProps>`
  width: calc(100% - 25px);
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 0 25px 0 0;
  overflow-y: auto;
`

export const WrapperField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40px;
  margin: 0 0 20px 0;
`

export const WrapperDescription = styled.div`
  width: 100%;
  height: 200px;
  text-align: justify;
  overflow-y: scroll;

  h3 {
    letter-spacing: 1.5px;
    line-height: 2 5px;
  }

  p {
    margin: 20px 0;
  }
`

export const WrapperFooter = styled.div<DefaultProps>`
  width: 100%;
  height: 50px;
  padding: 20px 0 0;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`
