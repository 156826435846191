import packageJson from '../../../../../package.json'

import Typography, { FontType } from '@components/UI/Typography'
import Separator from '@components/UI/Separator'
import { theme } from '@resources/theme'

import * as SC from './styles'

const Footer = () => {
  return (
    <SC.Container>
      <SC.WrapperEmpty />

      <SC.WrapperEmail>
        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.light}
          size={13}
        >
          Powered by ExchangeSpot - {packageJson.version}
        </Typography>
      </SC.WrapperEmail>

      <SC.WrapperPrivacy>
        <SC.LinkOption nav-item="link" to="" target="">
          Política de privacidade
        </SC.LinkOption>

        <Separator horizontalSize={15} />

        <Typography
          fontType={FontType.bold}
          color={theme.palette.primary.light}
          size={13}
        >
          |
        </Typography>

        <Separator horizontalSize={15} />

        <SC.LinkOption nav-item="link" to="" target="">
          Termos e condições
        </SC.LinkOption>
      </SC.WrapperPrivacy>
    </SC.Container>
  )
}

export default Footer
